.customListLoaderText {
    text-align: center !important;
    background-color: #fff !important;
    padding: 20px !important;
    font-weight: "bold" !important;
}

.customList {
    width: 100% !important;
    background-color: #fff !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    padding: 10px !important;

}

.customListItemContainer {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    cursor: pointer !important;
}