.mainHeader {
  padding: 10px !important;
  background-color: rgba(59, 56, 57, 1) !important;
  border-bottom: 1px solid #4444 !important;

  @media (max-width: 767px) {
    padding: 7px !important;
    position: sticky !important;
    top: 0px !important;
    z-index: 1000 !important;
    display: flex !important;
    overflow: hidden !important;
  }
}



.mainSlider {
  background-color: #f7f8fa !important;
  border-right: 1px solid #4444 !important;
  display: block !important;
  @media (max-width: 767px) {
    display: block !important;
    position: fixed !important;
    z-index: 10 !important;
    top: 45px !important;
    height: 100vh !important;
  }
}

.mainSliderHide {
  background-color: #f7f8fa !important;
  border-right: 1px solid #4444 !important;

  @media (max-width: 767px) {
    display: none !important;
  }
}

.mainContainer {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.secondContainer {
  display: flex !important;

  @media (max-width: 767px) {
    overflow: hidden !important;
  }
}

.userIcon {
  position: fixed !important;
  bottom: 10px !important;
  left: 10px !important;
}

.overlay {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 200 !important;
  cursor: pointer !important;
}

.overlayHide {
  display: none !important;
}