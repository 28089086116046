.additionalDataContainer {
    display: flex !important;
    align-items: center !important;
}

.additionalDataButton {
    margin: 15px 0px !important;
}

.emailTemplateFormTextField {
    margin: 10px 0px !important;
}

.emailTemplateEditorContainer {
    border: 1px solid #f1f1f1 !important;
    padding: 10px !important;
    min-height: 200px !important;

}


.emailTemplateImageHandler {
    padding: 20px;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    gap: 30px !important;

}

.emailTemplateAddImageButton {
    border-radius: 2px !important;
    border: 1px solid #f1f1f1 !important;
    color: #000 !important;
    margin-left: 5px !important;
}


.emailTemplatePopover {
    border-radius: 2px !important;
}