.whatsAppMainContainer {
    padding: 20px !important;
    width: 100%;
    background-color: #f7f8fa;
    overflow: auto !important;
}

.whatsAppOutLetContainer {
    width: 100% !important;
    margin-top: 20px !important;
    border-radius: 5px !important;
}


.whatsAppMenuContainer {
    width: 300px !important;
    padding: 20px 0px !important;
}

.whatsAppTagManagementWidget {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin-bottom: 10px !important;
    padding: 5px !important;
}

.whatsAppTemplateButtonContainer {
    display: flex !important;
    flex-direction: row !important;
    padding: 10px 0 !important;
}

.whatsAppTemplateButtonTextFieldValue {
    margin-left: 15px !important;
}

.whatsAppPhoneNumberAddButtonContainer {
    padding: 50px !important;
}

.whatsAppPhoneNumberAddButton {
    margin-bottom: 50px !important;
}

.editHelperText {
    padding: 20px !important;
    margin: 20px 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    background-color: #F5E8DD !important;
}

.editHelPerTextIcon {
    margin-right: 15px !important;
}

.previewDocumentLink {
    text-decoration: none !important;
    color: #ffffff !important;
}

.previewDocumentButtonContainer {
    width: 100% !important;
    margin-top: 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.whatsAppPhoneNumberDetails {
    padding: 20px !important;
}

.whatsAppBusinessDetailsDivider {
    margin: 10px 0 !important;
}

.whatsAppBusinessRegisteredPhoneNumberChip {
    background-color: #e0ff99 !important;
}

.whatsAppBusinessNotRegisteredPhoneNumberChip {
    background-color: #F5DAD2 !important;
}

.whatsAppBusinessPhoneNumberDetails {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}


.whatsAppTemplatePreviewPaper {
    display: flex !important;
    flex-direction: column !important;
}

.whatsAppTemplatePreviewDoc {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20px !important;
    background-color: #ccd0d5;
}


.whatsAppTemplatePreviewButton {
    display: flex !important;
    flex-direction: row !important;
    color: #00a5f4;
    align-items: center;
    justify-content: center;
    padding: 10px !important;
}

.whatsAppTemplatePreviewButtonIcon {
    margin-right: 10px !important;
}

.whatsAppHeaderMediaIcon {
    color: #FFFF !important;
}