#chatTextEditorContainer .rdw-suggestion-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #F1F1F1;
    min-width: 150px;
    max-height: 150px;
    overflow: auto;
    background: white;
    z-index: 100;
    left: 15px;
    bottom: 30px;
}

#chatTextEditorContainer .rdw-option-wrapper {
    position: absolute;
    background: unset;
    border: unset;
    top: 20px;
    left: -50px;
}



#chatTextEditorContainer .rdw-editor-toolbar {
    padding: unset;
    border-radius: unset;
    border: unset;
    display: unset;
    justify-content: unset;
    background: unset;
    flex-wrap: unset;
    font-size: unset;
    margin-bottom: unset;
    -webkit-user-select: unset;
    user-select: unset;
}

#chatTextEditorContainer .rdw-option-wrapper img {
    width: 25px;
    height: 25px;
}

#chatTextEditorContainer .rdw-emoji-modal {
    bottom: 40px;
    top: unset;
}

#chatTextEditorContainer .public-DraftEditor-content {
    padding-left: 20px !important;
}

.public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
    word-break: break-word;
}