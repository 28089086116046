.customDrawerCloseIcon {
    position: absolute !important;
    top: 20px !important;
    right: 20px !important;
    cursor: pointer !important;
}

.customDrawerContainer {
    padding: 20px !important;
    margin: 10px !important;
    max-width: 800px !important;
    min-width: 300px !important;
    /* overflow: hidden !important; */
    overflow-y: auto !important;
    overflow-x: hidden !important;
    margin-bottom: 80px !important;
    height: 100% !important;
}

.customDrawerTitle {
    text-align: left !important;
    padding-top: 10px !important;
}

.customDrawerCancelButton {
    margin: 20px !important;
    color: #fff !important;
}

.customDrawerSaveButton {
    margin: 20px !important;
}

.customDrawerModalActions {
    background-color: #ededef;
    display: flex !important;
    justify-content: space-between !important;
    gap: 5px !important;
    position: absolute !important;
    bottom: 0 !important;

    width: 100% !important;
    @media (max-width: 1400px) {
        position: sticky !important;
    }
}