.conversationContainer {
    border-radius: 7px !important;
}

.chatListContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 101px) !important;
    overflow: hidden !important;
    border-radius: 7px !important;
}

.chatList {
    overflow: auto;
}

.messageInputContainer {
    display: flex;
    flex-direction: row;
    padding: 7px;
    background-color: rgba(0, 0, 0, 0.05) !important;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
}

.messageImagePreviewSendButtonContainer {
    display: flex !important;
    flex-direction: row !important;
    padding: 7px !important;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    align-items: flex-end !important;
    justify-content: space-between !important;
}

.messageInputTextField {
    border-radius: 7px !important;
    background-color: rgb(77, 94, 127, 0.15);
    overflow: auto !important;
    line-height: normal !important;
    scrollbar-width: none !important;
    border: none !important;
    padding: 5px !important;
    padding-left: 20px !important;
}

.filePreviewContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0 20px !important;
}

.filePreviewHeader {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    align-items: center !important;
    text-align: center !important;
}

.filePreviewImage {
    display: flex !important;
    width: 100% !important;
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
}

.filePreviewContent {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    height: 100% !important;
}


.addTemplateChatButtonClosed {
    transition: transform 0.3s ease;
}

.addTemplateChatButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageReplyContainer {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    justify-content: space-between !important;

}

.imageReplyIconContainer {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.addTemplateChatButton {
    margin-right: 10px !important;
    height: 100% !important;

    @media screen and (max-width: 767px) {
        display: none !important;

    }
}

.addTemplateChatIconButton {
    display: none !important;

    @media screen and (max-width: 767px) {
        display: flex !important;
        height: 100% !important;

    }
}

.addAttachmentButton {
    margin-right: 10px !important;
    height: 100% !important;
}

.addTemplateChatButtonOpen {
    transition: transform 0.3s ease;
}

.rotate {
    transform: rotate(45deg);
    /* Adjust the angle as needed */
}

.chatHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #88aa91 !important;
    color: #fff !important;
    border-top-right-radius: 7px !important;
    border-top-left-radius: 7px !important;
}

.chatListHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}

.chatListHeaderTitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.noMessages {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100% !important;
    justify-content: center;
}

.repliedMessage {
    background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
    padding: 5px !important;
    border-radius: 5px !important;
    border-left: 2px solid #455a64 !important;
    width: 96% !important;
}

.repliedMessageContainer {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
    position: sticky !important;
    bottom: 0 !important;
    left: 0 !important;
}

.liveMessageReply {
    background-color: #f0f0f0 !important;
}

.oldMessageReply {
    background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
}

.noMoreMessages {
    margin-top: 20px !important;
}

.addNewChanelButton {
    background-color: #455a64 !important;
    color: #fff !important;
}

.authenticatedUserMessages {
    padding: 5px !important;
    background-color: #d1e4d6 !important;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
    margin-right: 10px !important;
    max-width: 100%;
    overflow: hidden;
}

.otherUsersMessages {
    background-color: #cecece;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    padding: 5px !important;
    margin-left: 10px !important;
    max-width: 100%;
    overflow: hidden;
}


.chatExtraActionList {
    background-color: #f0f0f0 !important;
}


.messagesContainerHeight {
    height: calc(100vh - 227px) !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.mainContainer {
    padding: 20px !important;
    background-color: #f0f0f0 !important;
    width: 100% !important;
    height: auto;
    min-height: 100%;
    overflow: hidden !important;

    @media (max-width: 767px) {
        margin: 0px !important;
    }
}

.gridContainer {
    margin: 0 !important;
    padding: 0 !important;
}

.messageContainerPadding {
    padding: 20px !important;
}


.modal {
    padding: 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

}

.fileExplorerPaper {
    padding: 5px !important;
    max-width: 500px !important;
}

.fileExplorerPaper img {
    max-width: 100% !important;

}

.messageContent {
    padding: 5px !important;
    display: flex !important;
    flex-direction: column !important;
}


.messageItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: flex-end;
}

.replyMessageContainer {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.authenticatedUserMessageContainer {
    justify-content: flex-end;
}

.otherUsersMessageContainer {
    justify-content: flex-start;
}

.searchForChats {
    border: #607d8b 1px solid !important;
    width: 100%;
    margin-top: 20px !important;
}

.messagesNotRead {
    padding: 0 !important;
}

.listItem {
    display: flex !important;
    flex-direction: row !important;
    padding: 10px !important;
    align-items: center !important;
}

.listItemNotRead {
    background-color: #f0f0f0 !important;
}

.chatUserInfo {
    display: flex;
    align-items: center;
}


.customInfiniteScrollList {
    scrollbar-width: none !important;
    overflow: auto !important;
}

.messageStatus {
    margin-left: 5px !important;
}

.messageText {
    padding: 5px 0px !important;
}

.renderFileMessage {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.renderFileMessageLabel {
    padding: 5px !important;
}

.renderFile {
    text-decoration: none !important;
    color: #455a64;
}