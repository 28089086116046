.productPageMainContainer {
    padding: 20px !important;
    width: 100% !important;
    overflow-x: hidden !important;
}

.productPageTag {
    margin-right: 5px !important;
}

.productPageGridContainer {
    display: flex;
    align-items: center;
}

.productPageSpaceAfterText {
    margin-right: 5px !important;
    font-weight: bold !important;
}

.productPageImageGalleryGrid {
    border-radius: 5px !important;
    box-shadow: 0px 0px 5px 0px #F7F8FA;
}


.editProductOrderOffer {
    display: flex !important;
    word-break: break-all !important;
    flex-direction: column !important;
    row-gap: 1 !important;
}

