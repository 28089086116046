.mainContainer {
  padding: 15px !important;
  width: 100%;
  background-color: #ffffff;
}

.gridContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column !important;
}

.typography {
  display: flex;
  justify-content: space-between;
  color: #444;
  border-right: 1px solid #444;
  padding: 5px;
  padding-right: 10px;
}

.typographyTotal {
  font-size: 20px !important;
  font-weight: 800 !important;
  float: right !important;
}

.mainBox {
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.divider {
  padding: 10px;
  margin-bottom: -5px;
  margin-top: 20px;
}

.optionButtons {
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 120px;
  }
}

.deleteButton {
  width: 100%;
  text-align: right;
  margin: -10px;
}

.deleteConfim {
  width: 100%;
  text-align: right;
  margin: -10px;
}

.settingsForm {
  padding: 20px 0 !important
}

.transportMethodSection {
  padding: 7px !important;
  margin-bottom: 20px !important;
  background-color: #f7f8fa !important;
}

.transportMethodsSwitchContainer {
  display: flex !important;
  justify-content: space-between !important;
  margin: 10px 0 !important;

}

.transportMethodsToggleButtonGroup {
  display: flex !important;
  margin-top: 10px !important;
  gap: 5px !important;
  justify-content: center !important;
}

.transportMethodsIsDefaultText {
  margin: 8px !important;
  margin-left: 17px !important;
}

.oblioSettingsButtonContainer {
  display: flex !important;
  align-items: center !important;
}

.settingContainerGrid {
  margin-bottom: 20px !important;
}