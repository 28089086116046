.mainContainer {
  padding: 15px !important;
  width: 100%;
  background-color: #fff;
}

.gridContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column !important;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.invoicedChip {
  background-color: #009892 !important;
  color: #fff !important;
  font-weight: bolder !important;

}

.invoiceButton {
  background-color: #009892 !important;
}

.collectButton {
  background-color: #36b37e !important;
}

.extraOptionContainerGrid {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px !important;
  align-items: center !important;
}

.billingDocStatusContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.billingDocStatus {
  margin-left: 10px !important;
}

.collected {
  background-color: #36b37e !important;
  color: #fff !important;
  font-weight: bolder !important;
}

.notCollected {
  background-color: #f36f21 !important;
  color: #fff !important;
}

.canceled {
  background-color: #D37676 !important;
  color: #fff !important;
}

.corrected {
  background-color: #F6995C !important;
  color: #fff !important;
}


.customerNotePanel {
  background-color: #fff !important;
  border-radius: 5px !important;
}

.listItemBillingDoc {
  background-color: #ffff !important;
  padding: 8px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  gap: 8px !important;
  margin-bottom: 8px !important;
}

.containers {
  width: 50% !important;

  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.containerProduct {
  width: 50%;
  display: flex;
  height: 100%;

  @media (max-width: 900px) {
    height: auto !important;
    width: 100%;
  }
}



.checkBox {
  position: absolute !important;
  top: -3px;
  left: -3px !important;

  @media (max-width: 900px) {
    right: -3px !important;
    left: unset !important;
  }
}

.autocompleteGrid {
  display: inline-block;
  align-self: flex-end;
  padding-right: 5px;
}

.secondGrid {
  padding: 15px 0px 0px 15px;
}

.detailsProduct {
  width: 50% !important;

  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.mainContainerBox {
  margin: 50px 10% 0px 10%;
}

.typography {
  display: flex;
  justify-content: space-between;
  color: #444;
  border-right: 1px solid #444;
  padding: 5px;
  padding-right: 10px;
}

.productImage {
  padding-right: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 75px !important;
  height: 100px !important;
  object-fit: cover !important;
  margin-left: 15px !important;
}

.typographyTotal {
  font-size: 20px !important;
  font-weight: 800 !important;
  float: right !important;
}

.mainBox {
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.divider {
  padding: 10px;
  margin-bottom: -5px;
  margin-top: 20px;
}

.optionButtons {
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 120px;
  }
}

.deleteButton {
  width: 100%;
  text-align: right;
  margin-top: 15px;
  margin-right: 0px !important;
}

.deleteConfirm {
  width: 100%;
  text-align: right;
  margin-top: 15px;
}

.expandButton {
  background-color: #009846 !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.accordionDetails {
  width: 100% !important;
}

.deleteIcon {
  color: #f23 !important;
  background-color: #4442 !important;
}

.closeIcon {
  color: #000 !important;
  background-color: #4442 !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.confirmDelete {
  color: #f23 !important;
  background-color: #4442 !important;
  border-radius: 20px !important;
}

.accordionSummary {
  margin-right: 20px !important;
}

.grid {
  margin: 7px !important;
}

.fromControlLabel {
  margin-left: -2px !important;
}

.invoicePanelWarning {
  background-color: #fcdec1 !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  padding-bottom: 10px !important;
  padding: 10px !important;
}

.totalPanel {
  display: flex !important;
  flex-direction: column !important;
  text-align: end !important;
}

.totalPanelForClient {
  display: flex !important;
  flex-direction: column !important;
  text-align: start !important;
}

.quantityOrderOffer {
  padding-top: 20px !important;
}