

.container {
  display: flex !important;
  padding: 10px !important;
  justify-content: space-between !important;
  overflow: hidden !important;
  position: relative !important;
  max-width: 40px !important;
  width: 180px;
  transition-timing-function: ease-in-out !important;
  transition: 0.95s !important;

  /* Move into place */
  transform: translateY(0) !important;
  @media (max-width: 767px) {
    max-width: 200px !important;
  }
}



.container:hover {
  display: flex !important;
  padding: 10px !important;
  justify-content: space-between !important;
  overflow: hidden !important;
  position: relative !important;
  max-width: 200px !important;
  transition-timing-function: ease-in-out !important;

  transition: 0.5s !important;

  /* Move into place */
  transform: translateY(0) !important;
}

.slider {
  display: flex !important;
  flex-direction: column !important;
  height: calc(100vh - 85px);
  width: 100% !important;
  margin-right: 10px !important;
  justify-content: space-between !important;
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

.listItem {
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
}

.nestedListItem {
  padding-left: 35px !important;
  color: #000 !important;
  background: #f0f0f0 !important;
  box-shadow: "inset 0 0 25px #00000033";
}

.arrowContainer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
  width: 100% !important;
}
