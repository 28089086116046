.noDataFoundContainer {
  width: 150px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
  font-weight: 300;
  text-align: center !important;
  color: #757575 !important;
}

.noDataFoundImage {
  width: 100% !important;
  opacity: 0.75 !important;
}

.genericModalCloseIcon {
  cursor: pointer !important;
}

.genericModalDialogContent {
  padding-top: 30px !important;
}

.missingData {
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  flex-direction: column !important;
}


.fieldSet {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 5px !important;
}

.fieldSetLegend {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.darkPastelRedColor {
  color: #f33 !important;
}

.searchField {
  display: flex;
  background-color: #fff;
  border-radius: 50px;
  height: 35px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;

  @media (max-width: 992px) {
    margin: unset;
  }
}

.genericModalTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0px 15px !important;
}

.genericModalExtraOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}



.fullWidthElement {
  width: 100% !important;
}

.mainContainer {
  padding: 20px !important;
  width: 100% !important;
  overflow-x: hidden !important;

  @media (max-width: 767px) {
    margin: 0px !important;
  }
}

.addButton {
  border-radius: 20px !important;
}

.specialDividerTypography {
  margin-bottom: -8 !important;
  margin-left: 0 !important;
  background-color: #fff !important;
  padding: 5 !important;
  color: #757575 !important;
  z-index: 100 !important;
}

.centeredElementRow {
  width: 100% !important;
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
}

.dynamicTabsChip {
  margin: 10px !important;
  font-size: 13px !important;
  font-weight: 400 !important;

  @media (max-width: 900px) {
    margin: 10px 0px !important;
  }
}

.centeredElementColumn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;

}

.centerElement {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
}

.specialDividerContainer {
  margin-top: -5 !important;
}

.modalActions {
  display: flex !important;
  justify-content: space-between !important;
  gap: 30px !important;
}

.editClientModalEditButton {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
}

.specialDividerSecondContainer {
  margin-bottom: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
}



.boldTypography {
  font-weight: bold !important;
}


.loginWithFacebookButton {
  background-color: #1877f2 !important;
  border: 0 !important;
  border-radius: 4px !important;
  color: #fff !important;
  cursor: pointer !important;
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: bold !important;
  height: 40px !important;
  padding: 0 24px !important;
}



.chatTextEditorContainer {
  margin-left: 45px !important;
}



.toggleButtons {
  width: 100% !important;
  background-color: "#fff";
}


.customDrawer {
  z-index: 9999999 !important;
}

.nonSelected {
  background-color: #fff !important;
  color: #4d5e80 !important;
  margin-top: 10px !important;
  max-width: 40px !important;

  @media (max-width: 767px) {
    background-color: #fff;
    color: #4d5e80 !important;
    margin-top: 10px !important;
  }
}

.selected {
  background-color: #fff !important;
  color: rgba(0, 152, 70, 1) !important;
  max-width: 40px !important;
  margin-top: 10px !important;

  @media (max-width: 767px) {
    background-color: #fff !important;
    color: rgba(0, 152, 70, 1) !important;
    margin-top: 10px !important;
  }
}

.selectedText {
  white-space: nowrap !important;
  color: rgba(0, 152, 70, 1) !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
  cursor: pointer !important;
}

.nonSelectedText {
  white-space: nowrap !important;
  color: #000 !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
  cursor: pointer !important;
}

.observationWidgetContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.observationWidgetIcon {
  margin-right: 5px !important;
}

.helperText {
  display: flex !important;
  justify-content: space-between !important;
}

.filterGridContainer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}