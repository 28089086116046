.App {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgb(88, 98, 93);
  background: radial-gradient(circle, rgba(88, 98, 93, 0.5158438375350141) 20%, rgba(59, 56, 57, 0.9220063025210083) 89%);
  object-fit: contain;
  background-size: cover;
  border: none !important;
}

.App-Column {
  flex-direction: column;
}

.ReCAPTCHA {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 20px;
}

.loginContainer {
  width: 30%;
  padding: 60px;
  height: auto;
  background-color: rgba(255, 255, 255, 1) !important;
  border: none !important;


  @media (max-width: 1250px) {
    width: 40%;
    padding: 40px;
    height: auto;
  }

  @media (max-width: 900px) {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 767px) {
    width: 350px;
    height: 500px;
  }
}


.img {
  width: 100%;
}

.gridMainContainer {
  height: 100%;

}

.firstGrid {
  height: 100%;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.imageContainer {
  display: flex;
  align-items: center;
  padding-right: 45px;

  @media (max-width: 900px) {
    display: none;
  }
}

.textfield {
  margin-bottom: 20px !important;
}

.form {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.submit {
  padding: 10px !important;
  margin-top: 40px !important;
  color: #fff !important;
}

.errorText {
  font-size: 17px !important;
  margin: 15px !important;
  color: red !important;
}