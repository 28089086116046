.mainContainer {
    padding: 10px !important;
}


.clientFormContainer {
    margin-top: -40px !important;
}

.checkboxTextError {
    color: red !important;
}