.box {
  background-color: #ededef !important;
  border-radius: 7px;
}
.typography {
  color: #444 !important;
  padding: 10px 0px 0px 10px !important;
  font-weight: 600 !important;
}
.typographyError {
  color: #f33 !important;
  padding: 10px 0px 0px 10px !important;
  font-size: 11px  !important;;
  margin-top:-1px  !important;;
}
.children {
  padding: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.headerButton {
  margin: 5px;
  color: #444;
}
