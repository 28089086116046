.SUCCESS {
  background-color: #E0FBE2 !important;
}

.ERROR {
  background-color: #FF9F9F !important;
}

.INFO {
  background-color: #BED7DC !important;
}

.WARNING {
  background-color: #F6F7C4 !important;
}