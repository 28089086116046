.dropzoneArea{
    display:flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    border-color: #D8D8D8 ;
    border-styles: dashed;
    border-width: 2px;
    border-radius: 5px;
}