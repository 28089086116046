.displayOneNoteChip {
  background-color: #4444 !important;
  color: #000 !important;
  font-size: 17px !important;
  border-radius: 5px !important;
}

.displayOneNoteStepIconComponent {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  justify-content: space-between !important;
}

.displayOneNoteSpan {
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 100% !important;
  size: 10px;
  padding: 7px !important;
  color: #fff !important;
  margin-left: 10px !important;
}

.displayOneNoteBox {
  padding: 20px !important;
  border-radius: 7px;
  background-color: #fff !important;
  /* border: 0.1px solid #009846 !important; */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  position: relative !important;
}

.displayOneNoteButtons {
  position: absolute !important;
  top: 20px !important;
  right: 15px !important;
}

.displayOneNoteVisibleToClientChip {
  margin-right: 15px !important;
}

.displayOneNoteVisibleToClientChipGrid {
  text-align: end !important;
}



.emailPreviewGrid {
  width: 100px !important;

  @media (max-width: 767px) {
    width: 100% !important;
  }
}


.emailPreviewBox {
  white-space: initial !important;
  background-color: #fff !important;
  margin: 0 auto;
  word-wrap: break-word !important;
  background-color:
    #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,
      0, 0, 0.1);
  /* overflow-y: scroll !important; */
  scrollbar-width:thin !important;
  /* max-height: 500px !important; */
}