.contactPaper {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.contactStatusContainer {
  position: absolute !important;
  top: 17px !important;
  right: -15px !important;
  padding: 5px !important;
  z-index: 1 !important;
}

.contactStatusContainerFold {
  position: absolute !important;
  top: 2px !important;
  right: -15px !important;
  border-left: 15px solid transparent !important;
  border-top: 15px solid #f7f8fa;
}

.dashboardContactsDivider {
  margin: 5px 0 !important;
}

.container1 {
  width: 60% !important;

  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.container2 {
  width: 40% !important;
  position: relative !important;

  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.container2SubContainer {
  position: sticky !important;
  top: 0 !important;
}

.container2ButtonsGrid {
  display: flex !important;
  justify-content: end !important;
  gap: 5px !important;
  height: 50px !important;
}

.dashboardContactsTabPanel {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 85vh !important;
  scrollbar-width: none !important;
  background-color: #FFF !important;
  border-radius: 5px !important;
}

.dashboardContactsTableTabPanel {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scrollbar-width: none !important;
  background-color: #FFF !important;
  border-radius: 5px !important;
}

.contactOrderLink {
  text-decoration: none !important;
  color: #000 !important;
}

.contactOrderNameTypography {
  font-weight: bold !important;
}

.contactOrderContainer {
  padding: 0 10px 10px 10px !important;
}

.orderStatusContactSummaryTypography {
  margin-top: 20px !important;
  font-weight: bold !important;
}

.contactWidgetHorizontalDividerNo1 {
  margin-bottom: 5px !important;
  margin-top: -10px !important;
}

.contactWidgetGridWithDivider {
  display: flex !important;
  justify-content: space-between !important;
}

.contactWidgetDialogPaper {
  min-width: 700px !important;
  width: 100% !important;

  @media screen and (max-width: 767px) {
    min-width: 300px !important;

  }
}


.contactSummaryClientName {
  display: flex !important;
  flex-direction: column !important;
}

.contactSummaryDetails {
  display: flex !important;
  vertical-align: middle !important;
  justify-content: space-between !important;
  width: 70% !important;
  margin-top: 10px !important;
  padding: 1px !important;
  justify-items: center !important;
  text-align: center !important;
}

.contactSummaryGridContainer {
  position: relative !important;
  padding: 5px !important;
}


.contactSummaryIconAndText {
  margin-right: 10px !important;
}


.panelGrid {
  margin: 7px !important;
}

.dialogContent {
  padding: 20px !important;
  min-width: 500px !important;

  @media screen and (max-width: 767px) {
    min-width: 300px !important;

  }
}

.customContactScrollList {
  border-radius: 0px 10px 10px 0px !important;
  /* scrollbar-width: none !important; */
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.messagesContainerHeight {
  height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden;
}


.addNewConversationButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}