.mainContainer {
  background-color: #f7faf8;
  padding: 20px !important;
  width: 100% !important;
  overflow-x: hidden !important;
  @media (max-width: 767px) {
    padding: 10px !important;
    padding-bottom: 100px !important;
  }
}

.welcomeBackWidget {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}


.dashboardMessagesContainerHeight {
  height: calc(100vh - 227px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}