.mainContainer {
  width: 100%;
  background-color: #fff;
}

.containers {
  width: 50% !important;

  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.mainContainerBox {
  margin: 20px 10% 70px 10%;

  @media (max-width: 900px) {
    margin: 20px 5% 70px 5%;
  }
}

.header {
  box-shadow: #4444 0px 3px 5px;
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  position: sticky;
  top: 0px;
  bottom: 10px;
  z-index: 2000;
  background-color: #fff;

}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #3b3839;
  width: 100%;
  padding: 0px 50px !important;
}

.orderOfferCustomPanel {
  display: flex !important;
  justify-content: space-between !important;
}

.orderOfferCustomPanelErrorText {
  color: #f33 !important;
}

.footerText {
  margin: 20px !important;
  color: #fff;
  text-align: center;

  @media (max-width: 900px) {
    color: #fff;
    text-align: center;
  }
}

.footerGrid {
  gap: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.saveButton {
  margin: 20px 50px 20px 20px !important;
}


.orderOfferHeaderImage {
  margin-left: 50px;
}

.title {
  text-align: center;
  color: #5b6067;
  margin-bottom: 10px;
}

.subTitles {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}



.linkText {
  color: #fff !important;
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important;
  margin: 10px !important;
}

.linkTextCategory {
  color: #b0cb1f !important;
  margin: 10px !important;
}

.linkIcon {
  color: #b0cb1f !important;
  margin-right: 5px !important;
}


.contactFooterGrid {
  margin-top: 50px !important;
  gap: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  text-align: start !important;

  @media (max-width: 900px) {
    margin-top: 5px !important;
    display: flex !important;
    flex-direction: column !important;
    text-align: center !important;
  }
}

.jaggedBorderContainer {
  box-sizing: border-box;
  background-color: #f8f8f8;
}

.orderSummaryContainer {
  padding: 10px !important
}

.jaggedBorderHeader::after {
  content: "";
  display: block;
  height: 10px;
  /* TODO Add browser prefixes */
  background: linear-gradient(45deg, transparent 33.333%, #f8f8f8 33.333%, #f8f8f8 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #f8f8f8 33.333%, #f8f8f8 66.667%, transparent 66.667%);
  background-size: 8px 20px;
  /* toothSize doubleHeight */
  background-position: 0 -10px;
  /* horizontalOffset -height */
}

.jaggedBorderFooter:after {
  content: "";
  display: block;
  height: 10px;
  /* TODO Add browser prefixes */
  background: linear-gradient(45deg, transparent 33.333%, #f8f8f8 33.333%, #f8f8f8 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #f8f8f8 33.333%, #f8f8f8 66.667%, transparent 66.667%);
  background-size: 8px 20px;
  /* toothSize doubleHeight */
  background-position: 0 -10px;
  /* horizontalOffset -height */
}

.jaggedBorderFlipVertical {
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
  -ms-filter: flipv;
  /*IE*/
  filter: flipv;
  /*IE*/
}

.gridOrderDetailsTextWithProduct {
  padding: 10px !important;
  background-color: #ffff !important;
  color: #a873a3
}

.gridOrderDetailsText {
  padding: 10px !important;
  background-color: #f8f8f8 !important;
  color: #000
}

.orderDetailsGridContainer {
  background-color: #f8f8f8 !important;
  margin: 0px !important;
  width: 100% !important;
}