.container {
  display: flex;
  justify-content: space-between;
  background-color: "#ff5";
  width: 100% !important;

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    background-color: "#ff5";
  }
}

.iconMenu {
  display: none;

  @media (max-width: 767px) {
    display: block;
    z-index: 999999 !important;
  }
}

.fab {
  background-color: #F7F8FA !important;
  margin-right: 10px !important;
  color: #4D5E80 !important;
}


.headerMessageFab {
  @media (max-width: 992px) {
    position: fixed !important;
    z-index: 99;
    bottom: 20px;
    right: 10px;
    background-color: #4D5E80 !important;
    color: #fff !important;
  }
}

.headerMessageFabDashboard {
  @media (max-width: 992px) {
    position: fixed !important;
    z-index: 99;
    bottom: 65px;
    right: 10px;
    background-color: #4D5E80 !important;
    color: #fff !important;
  }
}

.noUnreadMessages {
  display: none !important;
}

.pulse {
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 9999;
  font-size: 20px !important;
  width: 20px;
  height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50%;
  background-color: #f25 !important;
  animation: pulse-animation 2s infinite !important;

  @media screen and (max-width: 992px) {
    top: unset !important;
    bottom: 95px !important;
    right: 20px !important;
  }

}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}


.newMessagesContainer {
  position: relative;
}

.title {
  padding-top: 7px !important;
  padding-left: 15px !important;
  font-size: large;

  @media (max-width: 767px) {
    display: none;
  }
}

.fab {
  background-color: #fff !important;
  margin-right: 10px !important;
  color: #4d5e80 !important;
}

.fab:last-child {
  margin-right: 0px !important;
}

.secondContainer {
  display: flex;

}

.userSettingsAvatar {
  margin-right: 10px !important;
  background-color: #fff !important;
  margin-left: 10px !important;
  color: #4d5e80 !important;
}

.username {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;

  @media (max-width: 767px) {
    display: none;
  }
}

.searchField {
  display: flex;
  background-color: #fff;
  border-radius: 50px;
  height: 35px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;

  @media (max-width: 767px) {
    display: flex;
    border-radius: 50px;
    height: 35px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #fff;
  }
}