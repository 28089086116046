.loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.actionsWrapper {
    background-color: #7D8FB3;
    z-index: 100;
}

.overflowHidden {
    overflow: hidden;
}

.pdfPreviewPage {
    margin: 10px 0px 10px 10px;
    border: 1px solid #ccc;
}

.dialogContent {
    background: #eeeeee !important;
}